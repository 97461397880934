// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-company-js": () => import("./../../../src/pages/about-company.js" /* webpackChunkName: "component---src-pages-about-company-js" */),
  "component---src-pages-ampm-js": () => import("./../../../src/pages/ampm.js" /* webpackChunkName: "component---src-pages-ampm-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-damixa-js": () => import("./../../../src/pages/damixa.js" /* webpackChunkName: "component---src-pages-damixa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-wishbox-js": () => import("./../../../src/pages/wishbox.js" /* webpackChunkName: "component---src-pages-wishbox-js" */)
}

